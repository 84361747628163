/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
export default {
  methods: {
    isDangerFile(fileName) {
      if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(fileName)) {
        return true
      }
      // Filter php html js file
      if (/\.(php5?|html?|jsx?)$/i.test(fileName)) {
        return true
      }

      return false
    },

    allowExtension(fileName, extensions) {
      const exts = extensions.split(';')
      let allow = false
      exts.forEach(e => {
        const regex = new RegExp(`\\.(${e})$`, 'i')
        if (regex.test(fileName)) {
          allow = true
        }
      })
      return fileName !== 'unknown' ? allow : true
    },

    invalidFileSize(maxSize, size) {
      return (size >= 0 && maxSize > 0 && size > maxSize)
    },

    /**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
    humanFileSize(bytes, si = false, dp = 1) {
      const thresh = si ? 1000 : 1024

      if (Math.abs(bytes) < thresh) {
        return `${bytes} B`
      }

      const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      let u = -1
      const r = 10 ** dp

      do {
        bytes /= thresh
        ++u
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

      return `${bytes.toFixed(dp)} ${units[u]}`
    },
  },
}
