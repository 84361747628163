<template>
  <div
    class="upload"
    @click.stop
  >
    <div
      v-if="loading"
      class="badge link"
    >
      <span
        aria-hidden="true"
        style="vertical-align: baseline;"
        class="spinner-border spinner-border-sm text-primary"
      >
        <!---->
      </span>
    </div>
    <div
      v-else-if="hasAttachment && files && files[0].fileName"
      v-b-tooltip="files[0].fileName"
      class="mr-2 download"
    >
      <a
        class="badge badge-primary link"
        style="font-size: 12.5px;"
        :href="files[0].url"
        :download="files[0].fileName"
      >
        {{ files[0].fileName }}
      </a>
      <b-btn
        variant="none"
        class="btn-primary btn-sm py-0 px-1 remove"
        @click.prevent.stop="removeFile()"
      >
        <!-- <i class="far fa-trash-alt"></i> -->
        <span aria-hidden="true">&times;</span>
      </b-btn>
    </div>
    <file-upload
      ref="btnUpload"
      v-model="files"
      :disabled="loading"
      class="btn btn-outline-primary btn-sm"
      :input-id="id"
      :accept="extensions"
      :multiple="false"
      :custom-action="customAction"
      :size="1024 * 1024 * 10"
      @input-file="inputFile"
      @input-filter="inputFilter"
    >
      <i class="fas fa-paperclip" />
      {{ title }}
    </file-upload>
  </div>
</template>

<style lang="scss">
.upload {
  display: flex;
  // align-items: center;
  // justify-content: center;
}

.download {
  display: inline-flex;
  align-self: center;
  height: fit-content;
  max-width: calc(50%);

  .link {
    padding: 0.2rem + 0.2rem;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    color: #ffffff !important;
    display: flex;
    max-width: calc(100% - 20px);
    overflow-wrap: anywhere;

    &:hover {
      background-color: hsla(201, 51%, 46%, 0.9) !important;
    }
  }

  .remove {
    padding: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    border-radius: 0 4px 4px 0;
  }
}
</style>

<script>
// eslint-disable no-param-reassign
import FileUpload from 'vue-upload-component'
import mixins from '@pilar/mixins/upload'

export default {
  name: 'BtnUpload',
  components: {
    FileUpload,
  },
  mixins: [mixins],
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Anexar',
    },
    options: {
      type: Object,
    },
    extensions: {
      type: String,
      default: '',
    },
    uploadAuto: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
    },
    value: {},
  },
  data() {
    return {
      files: [],
      hasAttachment: false,
      loading: false,
    }
  },
  watch: {
    value: {
      deep: true,
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.updateFile(value)
          this.hasAttachment = true
        }
      },
    },
  },
  created() {
    if (this.value) {
      this.files[0] = this.value
      this.hasAttachment = true
    }
  },
  methods: {
    headers() {
      return {
        Authorization: `Bearer ${this.$store.getters['auth/accessToken']}`,
        departs: this.$store.getters['auth/departments'],
      }
    },
    updateFile(value) {
      if (this.files.length >= 1 && !value) {
        this.$refs.btnUpload.remove(this.files[0])
      } else if (this.files.length === 0) {
        const file = new File([''], value.fileName, value)
        this.$refs.btnUpload.add(file)
      } else {
        this.$refs.btnUpload.update(this.files[0], value)
      }
    },
    removeFile() {
      this.$emit('removed')
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (this.isDangerFile(newFile.name)) {
          this.$error('Arquivos não permitidos')
          return prevent()
        }

        if (!this.allowExtension(newFile.name, this.extensions)) {
          this.$warning(`Apenas arquivos ${this.extensions} são permitidos`)
          return prevent()
        }
      }
      return null
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        if (newFile.active && !oldFile.active && this.maxSize) {
          if (this.invalidFileSize(this.maxSize, newFile.size)) {
            this.$refs.btnUpload.update(newFile, { error: 'Tamanho inválido' })
          }
        }

        if (newFile.error && !oldFile.error) {
          this.$error(`Não foi possível salvar o arquivo ${newFile.name}. ${newFile.error}`)
          this.$refs.btnUpload.remove(newFile)
        }

        if (newFile.success && !oldFile.success) {
          this.loading = false
          const data = newFile.response
          if (data) {
            this.$refs.btnUpload.update(newFile, {
              id: data.id,
              url: data.url,
              fileName: newFile.name,
            })
            this.hasAttachment = true
            this.$emit('input', newFile)
            this.$emit('uploaded', data)
          }
        }
      }

      if (newFile && !oldFile && (!newFile.success && !newFile.active && !this.uploadAuto)) {
        this.$refs.btnUpload.update(newFile, {
          url: null,
          fileName: newFile.name,
        })
        this.hasAttachment = true
        this.$emit('input', newFile)
        this.$emit('added', newFile)
      }

      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (this.uploadAuto && !this.$refs.btnUpload.active) {
          this.$refs.btnUpload.active = true
        }
      }
    },
    async customAction(file, component) {
      if (file.fileObject) {
        this.loading = true
        const newFile = file
        newFile.data = this.options.body
        newFile.headers = this.headers()
        newFile.postAction = this.options.url
        const result = await component.uploadHtml5(newFile)
        return result
      }
      return null
    },
  },
}
</script>
